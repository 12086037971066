import {Component, HostListener, OnDestroy, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem, PrimeNGConfig} from "primeng/api";
import {SidebarService} from "src/app/services/sidebar/sidebar.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {User} from "src/app/models/entities/user";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {DataService} from "src/app/services/data.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  langList: MenuItem[] = [];
  userMenuList!: MenuItem[];
  user?: User;
  private unsubscriber = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private config: PrimeNGConfig,
    private sidebarService: SidebarService,
    private oidcSecurityService: OidcSecurityService,
    private dataService: DataService,
  ) {
    this.dataService.user$.pipe(
      takeUntil(this.unsubscriber)
    ).subscribe(user => {
      this.user = user;
      this.initMenuLists();
    });
  }

  ngOnInit(): void {
    this.initMenuLists();
    this.loadLang();
    this.sidebarService.setIsMenuOpen(window.innerWidth > 1400);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.sidebarService.setIsMenuOpen(window.innerWidth > 1400);
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  async initUserMenuList(): Promise<void> {
    await this.sidebarService.setMenu();

    this.userMenuList = [
      {
        label: `${this.user?.name ?? ''}`,
        disabled: true
      },
      {
        separator: true
      },
      {
        label: this.translateService.instant("header.logout"),
        command: () => {
          this.oidcSecurityService.logoff().subscribe(() => {
            console.log('logout')
          });
        },
        icon: "pi pi-sign-out"
      }
    ];
  }

  initMenuLists(): void {
    this.langList = [
      {
        label: "English", command: () => {
          this.translate("en");
          this.setLang("en");
        }
      },
      {
        label: "Deutsch", command: () => {
          this.translate("de");
          this.setLang("de");
        }
      }
    ];

    this.initUserMenuList();
  }

  toggleMenu(): void {
    this.sidebarService.setIsMenuOpen(!this.sidebarService.isSidebarOpen);
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get("primeng").subscribe(res => this.config.setTranslation(res));
  }

  loadLang(): void {
    let lang = localStorage.getItem(`lang`);
    if (!lang) {
      lang = "en";
      this.setLang(lang);
    }
    this.translate(lang);
  }

  setLang(lang: string): void {
    localStorage.setItem(`lang`, lang);
  }

}
