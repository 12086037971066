import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { DataService } from 'src/app/services/data.service';
import { Roles } from 'src/app/models/auth/roles.enum';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  isSidebarOpen = true;
  menuItems$ = new BehaviorSubject<MenuItem[]>(new Array<MenuItem>());
  isSidebarOpen$ = new BehaviorSubject<boolean>(true);

  constructor(
    private translateService: TranslateService,
    private spinner: SpinnerService,
    private data: DataService
  ) {
    this.spinner.show();
    this.setMenu().then(() => this.spinner.hide());
    this.translateService.onLangChange.subscribe(async () => {
      await this.setMenu();
    });
    this.isSidebarOpen$.subscribe(async isSidebarOpen => {
      this.isSidebarOpen = isSidebarOpen;
      await this.setMenu();
    });
  }

  async setMenu() {
    const user = this.data.user$.value;

    const menuItems: MenuItem[] = [
      {
        label: this.isSidebarOpen
          ? this.translateService.instant('menu.internal')
          : '',
        icon: 'pi pi-list',
        expanded: true,
        items: [
          {
            label: this.isSidebarOpen
              ? this.translateService.instant('menu.redirects')
              : '',
            icon: 'pi pi-directions',
            routerLink: '/redirects',
            visible: user?.role === Roles.Admin || user?.role === Roles.Normal,
          },
          {
            label: this.isSidebarOpen
              ? this.translateService.instant('menu.redirect_categories')
              : '',
            icon: 'pi pi-table',
            routerLink: '/redirect-categories',
            visible: user?.role === Roles.Admin,
          },
          {
            label: this.isSidebarOpen
              ? this.translateService.instant('menu.vcards')
              : '',
            icon: 'pi pi-id-card',
            routerLink: '/v-cards',
            visible: user?.role === Roles.Admin,
          },
          {
            label: this.isSidebarOpen
              ? this.translateService.instant('menu.users')
              : '',
            icon: 'pi pi-users',
            routerLink: '/users',
            visible: user?.role === Roles.Admin,
          },
        ],
      },
      {
        label: this.isSidebarOpen
          ? this.translateService.instant('menu.external')
          : '',
        icon: 'pi pi-list',
        expanded: true,
        items: [
          {
            label: this.isSidebarOpen
              ? this.translateService.instant('external.admin')
              : '',
            icon: 'pi pi-external-link',
            command: () => {
              window.open('https://admin.area49.app', '_blank');
            },
          },
          {
            label: this.isSidebarOpen
              ? this.translateService.instant('external.cloud')
              : '',
            icon: 'pi pi-external-link',
            command: () => {
              window.open('https://cloud.area49.de', '_blank');
            },
          },
        ],
      },
    ];
    this.menuItems$.next(menuItems);
  }

  setIsMenuOpen(isMenuOpen: boolean) {
    localStorage.setItem(`isMenuOpen`, isMenuOpen + '');
    this.setSideWidth(isMenuOpen);
  }

  setSideWidth(isSidebarOpen: boolean): void {
    this.isSidebarOpen$.next(isSidebarOpen);
  }
}
