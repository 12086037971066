<main>
  <ng-container>
    <app-header *ngIf="isLoggedIn"></app-header>

    <section class="app" *ngIf="isLoggedIn">
      <div>
        <section [ngClass]="{'sidebar-open': isSidebarOpen, 'sidebar-closed': !isSidebarOpen}">
          <app-sidebar></app-sidebar>
        </section>
      </div>
      <div class="component-wrapper">
        <section class="component">
          <router-outlet></router-outlet>
        </section>
      </div>
    </section>

    <section class="app" *ngIf="!isLoggedIn">
      <div class="component-wrapper-simple">
        <section class="component">
          <router-outlet></router-outlet>
        </section>
      </div>
    </section>

    <app-footer *ngIf="isLoggedIn"></app-footer>
  </ng-container>

  <p-toast></p-toast>
  <p-confirmDialog #cd key="confirmConfirmationDialog" [baseZIndex]="10000">
    <ng-template pTemplate="footer">
      <div class="wrapper-right btn-wrapper">
        <p-button label="{{'dialog.abort' | translate}}" class="btn icon-btn danger-icon-btn" icon="pi pi-times-circle"
                  (onClick)="cd.reject()"></p-button>
        <p-button label="{{'dialog.confirm' | translate}}" class="btn" icon="pi pi-check-circle"
                  (onClick)="cd.accept()"></p-button>
      </div>
    </ng-template>
  </p-confirmDialog>
</main>

<app-spinner></app-spinner>
