import {Component, OnInit} from "@angular/core";
import {environment} from "src/environments/environment";
import {SpinnerService} from "src/app/services/spinner/spinner.service";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {DataService} from "src/app/services/data.service";
import {SettingsService} from "src/app/services/settings/settings.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public frontendVersion = "0.0.0.0";
  public backendVersion = "0.0.0.0";

  constructor(
    private settings: SettingsService,
    private data: DataService,
    private spinner: SpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.frontendVersion = this.settings.versionSettings?.WebVersion ?? "0.0.0.0";

    this.spinner.show();
    this.data.getApiVersion()
      .pipe(catchError(error => {
        this.spinner.hide();
        return throwError(() => error);
      }))
      .subscribe(version => {
        this.spinner.hide();
        this.backendVersion = version;
      });
  }

  get privacyUrl(): string {
    return environment.privacyURL;
  }

  get imprintUrl(): string {
    return environment.imprintURL;
  }
}
