<footer [hidden]="true">
  <div class="frontend-version">
    <span>
      {{'footer.frontend' | translate}}:
    </span>
    <span>
      {{frontendVersion}}
    </span>
  </div>
  <span class="divider">
    |
  </span>
  <div class="backend-version">
    <span>
      {{'footer.backend' | translate}}:
    </span>
    <span>
      {{backendVersion}}
    </span>
    <span class="divider"> | </span>
  </div>
  <a [href]="privacyUrl">{{'footer.privacy' | translate}}</a>
  <span class="divider"> | </span>
  <a [href]="imprintUrl">{{'footer.imprint' | translate}}</a>
</footer>
