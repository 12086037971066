import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from "rxjs";
import {SpinnerService} from "src/app/services/spinner/spinner.service";
import {SidebarService} from "src/app/services/sidebar/sidebar.service";
import {takeUntil} from "rxjs/operators";
import {DataService} from "src/app/services/data.service";
import {Roles} from "src/app/models/auth/roles.enum";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  isSidebarOpen = true;

  title = 'Admin';
  isLoggedIn: boolean = false;

  private unsubscriber = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private spinner: SpinnerService,
    private sidebar: SidebarService,
    private dataService: DataService,
  ) {
    this.spinner.show();
    this.translateService.use(localStorage.getItem('lang') || translateService.defaultLang);
    this.sidebar.isSidebarOpen$.pipe(
      takeUntil(this.unsubscriber)
    ).subscribe(value => {
      this.isSidebarOpen = value;
    });
    this.dataService.user$.pipe(
      takeUntil(this.unsubscriber)
    ).subscribe(user => {
      if (user?.role && user.role == Roles.Guest) {
        this.spinner.show();
        this.isLoggedIn = false;
      } else if (user) {
        this.isLoggedIn = true;
      }
    });
  }
}
