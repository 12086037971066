import { Component } from '@angular/core';
import {SpinnerService} from "src/app/services/spinner/spinner.service";

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent  {

  showSpinnerState = false;

  constructor(
    public spinnerService: SpinnerService
  ) {
    this.spinnerService.showSpinnerState$.subscribe(value => {
      this.showSpinnerState = value;
    });
  }



}
