import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from "src/app/components/error/not-found/not-found.component";
import {AuthGuard} from "src/app/modules/shared/guards/auth.guard";
import {Roles} from "src/app/models/auth/roles.enum";

const routes: Routes = [
  {path: '404', component: NotFoundComponent},
  {
    path: '',
    redirectTo: 'redirects',
    pathMatch: 'full'
  },
  {
    path: 'redirects',
    loadChildren: () => import('src/app/modules/redirects/redirects.module').then(m => m.RedirectsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'redirect-categories',
    loadChildren: () => import('src/app/modules/redirect-categories/redirect-categories.module').then(m => m.RedirectCategoriesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'v-cards',
    loadChildren: () => import('src/app/modules/v-cards/v-cards.module').then(m => m.VCardsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'v-cards/:id/values',
    loadChildren: () => import('src/app/modules/v-card-values/v-card-values.module').then(m => m.VCardValuesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    pathMatch: 'full',
    loadChildren: () => import('src/app/modules/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard],
    data: {role: Roles.Admin}
  },
  {
    path: '**',
    loadChildren: () => import('src/app/modules/redirect/redirect.module').then(m => m.RedirectModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
